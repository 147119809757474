
import { Component, Prop, Vue } from 'vue-property-decorator'
import { declOfNum } from '@/plugins/helpers'
import { StakingRegisterType, StakingTermsType } from '@/interfaces/staking.type'
import PopupStaking from '@/components/popup/Staking.vue'
import PopupStakingUpdate from '@/components/popup/StakingUpdate.vue'
import { readBalances } from '@/store/main/getters'
import { Locales } from '@/i18n/locales'

@Component({
  components: {
    PopupStaking,
    PopupStakingUpdate,
  },
})
export default class FinStaking extends Vue {
  @Prop({
    required: true,
  })
  public stakingTerms!: StakingTermsType[]

  @Prop({
    required: true,
  })
  public stakingRegisters!: StakingRegisterType[]

  public showPopup: boolean = false
  public showPopupUpdate: boolean = false
  public stakingType: number = 0

  public row: StakingRegisterType | null = null

  get balances() {
    return readBalances(this.$store)
  }

  get availableForStaking() {
    return this.balances ? Number(this.balances.dxt_hold) + Number(this.balances.dxt) : 0
  }

  public getPeriod(num: number): string {
    let titles = ['month', 'months']
    if (this.$i18n.locale === Locales.RU) titles = ['месяц', 'месяца', 'месяцев']

    return `${num} ${declOfNum(num, titles, this.$i18n.locale)}`
  }

  public showStakingUpdatePopup(row: StakingRegisterType) {
    this.row = row
    this.showPopupUpdate = true
  }

  public getDate(date: string): string {
    return new Date(date).toLocaleDateString()
  }

  dataReload() {
    console.debug('%c FinStaking.vue dataReload()', 'color:gold;')
    this.$emit('data-reload')
  }

}
