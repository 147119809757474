
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import '@/assets/css/popup.css';
import { StakingError, StakingTermsType } from '@/interfaces/staking.type'
import Spinner from '@/components/Spinner.vue'
import { readBalances } from '@/store/main/getters'
import { api } from '@/api'

@Component({
  components: { Spinner },
})
export default class PopupStaking extends Vue {
  @Prop({
    required: true,
  })
  stakingTerms!: StakingTermsType[]

  @Watch('stakingAmount', {})
  onChangeStakingAmount(value: string, oldValue: string) {
    console.debug('%c onChangeStakingAmount(val: %s, oldVal: %s)', 'color:gold;', value, oldValue)

    const amount = this.stakingAmount
      .replace(/[^0-9.]/g, '')
      .replace(/(.*\..*)\./, '$1')

    console.debug('%c amount: %s', 'color:lime;', amount)

    this.stakingAmount = amount
  }

  public popupActive: boolean = false
  public confirm: boolean = false
  public curTypeInd: number = 0
  public curTermInd: number = 0
  public loading: boolean = false
  public stakingAmount: string = '0'

  get balances() {
    return readBalances(this.$store)
  }

  get availableForStaking() {
    return this.balances ? Number(this.balances.dxt_hold) + Number(this.balances.dxt) : 0
  }

  get curTerm() {
    return this.stakingTerms[this.curTypeInd].terms[this.curTermInd]
  }

  async submitStaking() {
    console.debug('%c submitStaking', 'color:gold;', this.confirm)
    if (this.loading) return

    if (!this.confirm) {
      this.$toast.warning('', {
        message: this.$t('staking.message.termsAgree').toString(),
        duration: 3000,
        position: 'bottom',
      })
      return
    }

    try {
      this.loading = true

      let amount: number = Number(this.stakingAmount)

      if (this.toFixedWithoutRounding(Number(this.stakingAmount)) === this.toFixedWithoutRounding(this.availableForStaking)) {
        amount = this.availableForStaking
      }
      const { data, status } = await api.createStaking(this.$store.state.main.token, {
        staking_terms: this.curTerm.id,
        quantity: amount,
      })

      console.debug('%c popup/Staking.vue DATA: %s', 'color:lime;', JSON.stringify(data, null, 4))

      this.$emit('data-reload')
      this.closePopup()

      this.$toast.success('', {
        message: this.$t('staking.message.stakingCreated').toString(),
        duration: 7000,
        position: 'bottom',
      })

    } catch (err) {
      console.debug('%c popup/Staking.vue submitStaking ERROR:', 'color:red;', err)

      console.debug('%c ******************************************** err:', 'color:orangered;')
      console.debug(err)
      console.debug('%c ******************************************** err.response:', 'color:orangered;')
      console.debug(err.response)
      console.debug('%c ********************************************', 'color:orangered;')

      const errMsg = err.response?.data?.error
      console.debug('%c err.response.data.error: %s', 'color:orangered;', errMsg)

      if (errMsg && errMsg in StakingError) {
        this.$toast.error('', {
          message: this.$t(`staking.error.${errMsg}`).toString(),
          duration: 7000,
          position: 'bottom',
        })
      }
    }
    this.loading = false
  }

  public toFixedWithoutRounding (n: any) {
    return n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
  }

  get profitAmount() {
    console.debug('%c this.curTerm: %s', 'color:aqua;', JSON.stringify(this.curTerm, null, 4))
    const amount = Number(this.stakingAmount)
    const percent = Number(this.curTerm.percent)
    const result = amount + (amount * percent / 100) * this.curTerm.period / 12
    return this.toFixedWithoutRounding(result)
  }

  setMax() {
    this.stakingAmount = this.toFixedWithoutRounding(this.availableForStaking)
  }

  closePopup() {
    this.popupActive = false
    setTimeout(() => {
      this.$emit('close')
    }, 1000)
  }

  mounted() {
    setTimeout(() => {
      this.popupActive = true
    }, 10)
  }
}
