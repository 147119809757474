
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { WithdrawalLimitsType, WalletType } from '@/interfaces/wallet.type'
import Spinner from '@/components/Spinner.vue'
import { api } from '@/api'
import { readBalances } from '@/store/main/getters'
import { WithdrawalError } from '@/interfaces/staking.type'

@Component({
  components: { Spinner },
})
export default class PopupWithdrawal extends Vue {
  @Prop({
    required: true,
  })
  wallet!: WalletType | null

  @Prop({
    required: true,
  })
  limits!: WithdrawalLimitsType

  public popupActive: boolean = false
  public loading: boolean = false
  public amount: number = 0
  public error: boolean = false

  public errMessages = [
    WithdrawalError.MaxAmountLimit,
    WithdrawalError.MinAmountLimit,
    WithdrawalError.InsufficientFunds,
    WithdrawalError.ErrorGettingBalance,
    WithdrawalError.MainWalletIsNotSet,
    WithdrawalError.MainWalletLimit24h,
  ]
  // public errMessages = Object.keys(WithdrawalError)

  get amountReceivable() {
    if (this.amount === 0) return 0

    return this.amount - this.amount * 0.01
  }

  get available() {
    return Number(readBalances(this.$store)?.dxt || 0)
  }

  async requestWithdrawal() {
    console.debug('%c PopupWithdrawal.vue requestWithdrawal', 'color:gold;')

    if (this.limits) {

      if (this.amount < Number(this.limits.min_amount)) {
        this.$toast.error('', {
          message: `${this.$t(`staking.withdrawError.${WithdrawalError.MinAmountLimit}`).toString()} ${Number(this.limits.min_amount).toFixed(2)} DEXNET`,
          duration: 10000,
          position: 'bottom',
        })

        // this.amount = Number(this.limits.min_amount)
        this.error = true

        return
      }

      if (this.amount > Number(this.limits.max_amount)) {
        this.$toast.error('', {
          message: `${this.$t(`staking.withdrawError.${WithdrawalError.MaxAmountLimit}`).toString()} ${Number(this.limits.max_amount).toFixed(2)} DEXNET`,
          duration: 10000,
          position: 'bottom',
        })

        // this.amount = Number(this.limits.max_amount)
        this.error = true

        return
      }
    }

    this.loading = true

    try {
      const result = await api.withdrawRequest(
        this.$store.state.main.token, {
          amount: this.amount,
        })

      console.debug('%c RESULT:', 'color:orangered;', result)

      if (result.data) {
        this.$toast.success('', {
          message: this.$t('staking.message.withdrawalRequestCreated').toString(),
          duration: 10000,
          position: 'bottom',
        })

        this.$emit('data-reload')

        this.closePopup()
      } else {
        this.$toast.error('', {
          message: JSON.stringify(result, null, 4),
          duration: 10000,
          position: 'bottom',
        })
      }
    } catch (err) {
      console.debug('%c PopupWithdrawal.vue requestWithdrawal ERROR:', 'color:red;', err)

      const errMsg = err.response?.data?.error

      let message = this.$t('staking.withdrawError.UndefinedError').toString()
      const duration = 10000

      if (errMsg && this.errMessages.includes(errMsg) && this.limits) {

        message = this.$t(`staking.withdrawError.${errMsg}`).toString()

        if (errMsg === WithdrawalError.MinAmountLimit) {
          message = `${message} ${Number(this.limits.min_amount).toFixed(2)} DEXNET`
        }

        if (errMsg === WithdrawalError.MaxAmountLimit) {
          message = `${message} ${Number(this.limits.max_amount).toFixed(2)} DEXNET`
        }
      }
      this.$toast.error('', { message, duration, position: 'bottom' })
    }

    this.loading = false
  }

  @Watch('amount')
  fixAmount(val) {
    const amount = String(val)
      .replace(/[^0-9.]/g, '')
      .replace(/(.*\..*)\./, '$1')

    this.amount = Number(amount)
    this.error = false
  }

  closePopup() {
    this.popupActive = false
    setTimeout(() => {
      this.$emit('close')
    }, 1000)
  }

  mounted() {
    console.debug('%c PopupWithdrawal mounted!', 'color:gold;')

    setTimeout(() => {
      this.popupActive = true
    }, 10)
  }
}
