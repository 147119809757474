
import { Component, Prop, Vue } from 'vue-property-decorator'
import { RegisterType, TransactionHistoryType, TransactionStatus } from '@/interfaces/staking.type'
import FinPagination from '@/components/fin/Pagination.vue'
import FinSetWallet from '@/components/fin/SetWallet.vue'
import { WalletType } from '@/interfaces/wallet.type'


@Component({
  components: {
    FinPagination,
    FinSetWallet,
  },
})
export default class FinTransactions extends Vue {
  @Prop({})
  public history!: TransactionHistoryType | null

  @Prop({})
  public loading!: boolean

  @Prop({
    required: false,
    default: null,
  })
  wallet!: WalletType | null

  public TransactionStatus = TransactionStatus
  public RegisterType = RegisterType


  public getDate(timestamp: number) {
    return new Date(timestamp).toLocaleDateString()
  }

  public gotoPage(page: any) {
    console.debug('%c Transactions.vue gotoPage(%s)', 'color:lime;', page)
    this.$emit('goto-page', page)
  }

}
