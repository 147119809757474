
import { Component, Prop, Vue } from 'vue-property-decorator'
import Spinner from '@/components/Spinner.vue'
import { api } from '@/api'
import { WalletType } from '@/interfaces/wallet.type'

@Component({
  components: { Spinner },
})
export default class FinSetWallet extends Vue {
  @Prop({
    required: false,
    default: null,
  })
  wallet!: WalletType | null

  // public walletAddress: string = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984' // TODO REMOVE!!!
  public walletAddress: string = ''
  public loading: boolean = false
  public success: boolean = false
  public agree: boolean = false

  async setWalletAddress() {
    if (!this.agree || this.walletAddress === '') return

    this.loading = true
    try {
      const result = await api.setWithdrawalWallet(this.$store.state.main.token, {
        address: this.walletAddress,
      })

      if (result.data) {
        this.$emit('set-wallet', result.data)
      }

    } catch (err) {
      // Enum: "WalletAlreadySet" "InvalidAddress"
      console.debug('%c FinSetWallet.vue setWalletAddress() error:', 'color:red;', err.response)

      const errMsg = err.response?.data?.error
      if (errMsg === 'WalletAlreadySet') {
        this.$toast.error('', {
          message: this.$t('staking.error.WalletAlreadySet').toString(),
          duration: 7000,
          position: 'bottom',
        })
      } else if (errMsg === 'InvalidAddress') {
        this.$toast.error('', {
          message: this.$t('staking.error.InvalidAddress').toString(),
          duration: 7000,
          position: 'bottom',
        })
      } else {
        this.$toast.error('', {
          message: errMsg || 'Unknown error',
          duration: 7000,
          position: 'bottom',
        })
      }



    }
    this.loading = false
  }
}
