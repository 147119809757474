
import { Component, Prop, Vue } from 'vue-property-decorator'
import '@/assets/css/popup.css';
import { StakingError, StakingRegisterType, StakingTermsType } from '@/interfaces/staking.type'
import Spinner from '@/components/Spinner.vue'
import { readBalances } from '@/store/main/getters'
import { api } from '@/api'

@Component({
  components: { Spinner },
})
export default class PopupStakingUpdate extends Vue {
  @Prop({
    required: true,
  })
  // row!: StakingRegisterType | null
  row!: StakingRegisterType

  @Prop({
    required: true,
  })
  stakingTerms!: StakingTermsType[]

  public stakingType: number = 0

  public popupActive: boolean = false
  public curTypeInd: number = 0
  public curTermInd: number = 0
  public loading: boolean = false

  get balances() {
    return readBalances(this.$store)
  }

  get curTerm() {
    return this.stakingTerms[this.curTypeInd].terms[this.curTermInd]
  }

  async updateStaking() {
    console.debug('%c updateStaking', 'color:gold;')
    if (this.loading) return

    try {
      this.loading = true

      const { data } = await api.updateStaking(this.$store.state.main.token, {
        staking_register: this.row.id,
        staking_terms: this.curTerm.id,
      })

      console.debug('%c popup/StakingUpdate.vue DATA: %s', 'color:lime;', JSON.stringify(data, null, 4))

      this.$emit('data-reload')
      this.closePopup()

      this.$toast.success('', {
        message: this.$t('staking.message.stakingUpdated').toString(),
        duration: 7000,
        position: 'bottom',
      })

    } catch (err) {
      console.debug('%c popup/StakingUpdate.vue submitStaking ERROR:', 'color:red;', err)

      console.debug('%c ******************************************** err:', 'color:orangered;')
      console.debug(err)
      console.debug('%c ******************************************** err.response:', 'color:orangered;')
      console.debug(err.response)
      console.debug('%c ********************************************', 'color:orangered;')

      const errMsg = err.response?.data?.error
      console.debug('%c err.response.data.error: %s', 'color:orangered;', errMsg)

      if (errMsg && errMsg in StakingError) {
        this.$toast.error('', {
          message: this.$t(`staking.error.${errMsg}`).toString(),
          duration: 7000,
          position: 'bottom',
        })
      }
    }

    this.loading = false

    // setTimeout(() => {
    //   this.loading = false
    //   this.$emit('data-reload')
    //   this.closePopup()
    // }, 1000)
  }

  get profitAmount() {
    if (!this.row) return '0'

    const amount = Number(this.row.quantity)
    const percent = Number(this.curTerm.percent)
    const result = amount + (amount * percent / 100) * this.curTerm.period / 12
    return result.toFixed(2)
  }

  setCurrentParams() {
    if (!this.row) return

    console.debug('%c setCurrentParams(row.staking_type: %s, row.period: %s)', 'color:aqua;', this.row.staking_type, this.row.period)

    const typeIndex = this.stakingTerms.findIndex((type) => type.name === this.row.staking_type)

    if (typeIndex > -1) {
      const termIndex = this.stakingTerms[typeIndex].terms.findIndex((term) => term.period === this.row.period)

      if (termIndex > -1) {
        this.curTypeInd = typeIndex
        this.curTermInd = termIndex
      }
    }



    // this.curTypeInd = this.row.staking_terms
    // this.curTermInd = this.stakingTerms[this.curTypeInd].terms.findIndex((term) => term.id === this.row.staking_terms)
  }

  closePopup() {
    this.popupActive = false
    setTimeout(() => {
      this.$emit('close')
    }, 1000)
  }

  mounted() {
    console.debug('%c popup/StakingUpdate.vue mounted', 'color:gold;')

    setTimeout(() => {
      this.popupActive = true
    }, 10)

    // Вычисление выбранного типа и периода
    this.setCurrentParams() // TODO Вернуть
    setTimeout(this.setCurrentParams, 1000) // TODO REMOVE
  }
}
