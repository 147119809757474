
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TransactionHistoryType } from '@/interfaces/staking.type'
import Spinner from '@/components/Spinner.vue'

@Component({
  components: { Spinner },
})
export default class FinPagination extends Vue {
  @Prop({})
  public history!: TransactionHistoryType | null

  @Prop({})
  loading!: boolean

  get prev() { return this.history?.previous || null }
  get next() { return this.history?.next || null }
  get current() { return this.history?.current || null }
  get total() { return this.history?.count || null }

  get first() {
    if (!this.history) return null

    return this.history.current > 2 ? 1 : null
  }

  get last() {
    if (!this.history) return null

    return (this.history.count - 1) > this.history.current ? this.history.count : null
  }

  // get paginationItems() {
  //   // const pages = Math.ceil((this.history?.count || 0) / 10)
  //   const pages = this.history?.count || 0
  //   const current = this.history?.current || 1
  //
  //   if (pages <= 7) {
  //     return Array.from({ length: pages }, (_, i) => i + 1)
  //   } else {
  //     const startPages = [1, 2, 3]
  //     const endPages = [pages - 2, pages - 1, pages]
  //     const currentPages = current > 3 && current < pages - 2 ? [current] : []
  //
  //     return [...startPages, '...', ...currentPages, '...', ...endPages]
  //   }
  // }

  public gotoPage(page: number) {
    console.debug('%c Transactions.vue gotoPage(%s)', 'color:lime;', page)
    this.$emit('goto-page', page)
  }
}
