import { Locales } from '@/i18n/locales'

export const declOfNum = (n: number, titles: string[], locale: string): string => {

  if (locale === Locales.RU) {
    return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
  }

  return titles[n === 1 ? 0 : 1]
}
